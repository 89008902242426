import React from 'react'
import tw from 'twin.macro'

import { InputLabel } from './InputLabel'

type Props = {
  value?: string
  onChange: (val: string) => void
  name: string
  label?: string
  placeholder?: string
}
export const TextInput: React.FC<Props> = ({ value, onChange, name, label, placeholder }) => (
  <div tw="flex flex-col gap-1">
    {label && <InputLabel htmlFor={name} label={label} />}
    <input
      type={'text'}
      name={name}
      id={name}
      tw="shadow-sm focus:(ring-primary-500 border-primary-500) block w-full sm:text-sm border-gray-200 rounded-md"
      placeholder={placeholder}
      value={value}
      onChange={(x) => onChange(x.currentTarget.value)}
    />
  </div>
)
