import React from 'react'
import tw from 'twin.macro'

type Props = {
  htmlFor: string
  label: string
}
export const InputLabel: React.FC<Props> = ({ htmlFor, label }) => (
  <label htmlFor={htmlFor} tw="block w-full text-left text-sm font-medium text-gray-700">
    {label}
  </label>
)
