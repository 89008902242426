import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * @desc Made compatible with {GeolocationPositionError} and {PositionError} cause
 * PositionError been renamed to GeolocationPositionError in typescript 4.1.x and making
 * own compatible interface is most easiest way to avoid errors.
 */
export interface IGeolocationPositionError {
  readonly code: number
  readonly message: string
  readonly PERMISSION_DENIED: number
  readonly POSITION_UNAVAILABLE: number
  readonly TIMEOUT: number
}

export interface GeoLocationSensorState {
  loading: boolean
  accuracy: number | null
  altitude: number | null
  altitudeAccuracy: number | null
  heading: number | null
  latitude: number | null
  longitude: number | null
  speed: number | null
  timestamp: number | null
  error?: Error | IGeolocationPositionError
}

const useGeolocation = (enable = 0, options?: PositionOptions): GeoLocationSensorState => {
  let mounted = useRef(true)
  const [state, setState] = useState<GeoLocationSensorState>({
    loading: false,
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: null,
    longitude: null,
    speed: null,
    timestamp: Date.now(),
  })

  useEffect(() => {
    if (!enable) {
      return
    } else {
      setState((oldState) => ({ ...oldState, loading: true, error: undefined }))
    }

    const onError = (error: IGeolocationPositionError) =>
      mounted.current && setState((oldState) => ({ ...oldState, loading: false, error }))

    const onUpdate = (event: any) => {
      if (mounted.current) {
        setState({
          loading: false,
          accuracy: event.coords.accuracy,
          altitude: event.coords.altitude,
          altitudeAccuracy: event.coords.altitudeAccuracy,
          heading: event.coords.heading,
          latitude: event.coords.latitude,
          longitude: event.coords.longitude,
          speed: event.coords.speed,
          timestamp: event.timestamp,
        })
      }
    }

    navigator.geolocation.getCurrentPosition(onUpdate, onError, options)
    const watchId = navigator.geolocation.watchPosition(onUpdate, onError, options)

    return () => {
      mounted.current = false
      navigator.geolocation.clearWatch(watchId)
    }
  }, [enable, options])

  return state
}

export default useGeolocation
