import { css, cx } from '@emotion/css'
import { Popover, Transition } from '@headlessui/react'
import { LocationMarkerIcon, XIcon } from '@heroicons/react/solid'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, Fragment, useContext, useEffect, useState } from 'react'
import tw from 'twin.macro'

import { OnboardingContext } from '@/domain/common/OnboardingContext'
import { usePlausibleAsync } from '@/domain/common/usePlausibleAsync'
import { useCurrentUser } from '@/domain/user/useCurrentUser'

import { useFetchUserByNameQuery, UserOverviewFragment } from '../../../graphql/types'
import { ProfilePhoto } from '../../user/components/ProfilePhoto'
import { useDebounce } from '../useDebounce'
import useGeolocation from '../useGeolocation'

/**
 * NOTE: At the moment this is really UserSearch until we had in communities, but it will be changing soon
 * @constructor
 */
export const GymSearch: FC = () => {
  const [search, setSearch] = useState('')
  const [bounce, setBounce] = useState(false)
  const { user } = useCurrentUser()
  const plausible = usePlausibleAsync()
  const [geoLocate, setGeoLocate] = useState(0)
  const debouncedSearch = useDebounce(search, 200)
  const skip = debouncedSearch === ''
  const { data, refetch, loading, previousData } = useFetchUserByNameQuery({
    skip,
    variables: { name: `%${debouncedSearch}%` },
  })
  const { latitude, longitude, error: locError } = useGeolocation(geoLocate)
  const router = useRouter()

  useEffect(() => {
    plausible('search', { user: user?.id, term: debouncedSearch })
  }, [debouncedSearch])

  useEffect(() => {
    setBounce(router.query.search === 'true')
  }, [router.query.search])

  // the data object is set to null when the query is running, so we fall back to the previous data
  // (ie. don't change anything) only if the search isn't empty
  const users = (skip ? data : data || previousData)?.user

  // only show loading after 1s
  const debouncedLoading = useDebounce(loading, 1000)

  return (
    <form tw="mt-4 relative">
      <div tw="sm:flex">
        <div tw="min-w-0 flex-1">
          <div
            tw="relative rounded-md shadow-sm"
            css={
              bounce
                ? tw`animate-bounce ring-2 ring-offset-2 ring-green-500 ring-offset-gray-900`
                : {}
            }
            style={{ animationDelay: '-0.5s' }}
            onClick={() => setBounce(false)}
          >
            <input
              type="text"
              value={search}
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              tw="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 focus:ring-offset-gray-900"
            />
            <div tw="absolute inset-y-0 right-0 pr-3 flex items-center space-x-2">
              {debouncedSearch && (
                <button
                  type="button"
                  tw="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 rounded-md"
                  onClick={() => setSearch('')}
                >
                  <XIcon tw="h-5 w-5 text-gray-400" />
                </button>
              )}
              <button
                type="button"
                onClick={() => setGeoLocate(geoLocate + 1)}
                tw="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 rounded-md"
              >
                <LocationMarkerIcon
                  css={
                    locError
                      ? tw`text-danger-500`
                      : longitude !== null && latitude !== null
                      ? tw`text-success-500`
                      : tw`text-gray-400`
                  }
                  tw="h-5 w-5"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <SearchResults results={users} />
    </form>
  )
}

type SearchResultsProps = {
  results?: UserOverviewFragment[]
}

const SearchResults: FC<SearchResultsProps> = ({ results }) => {
  const [state, dispatch] = useContext(OnboardingContext)

  const resultCount = (results ?? []).length
  const open = results !== undefined

  useEffect(() => {
    if (open && !state.searchComplete) {
      dispatch({ type: 'update', payload: { searchComplete: true } })
    }
  }, [open])

  return (
    <div tw="w-full absolute">
      <Popover tw="relative">
        <Transition
          as={Fragment}
          show={open}
          {...{
            enter: cx(css(tw`transition ease-out duration-200`)),
            enterFrom: cx(css(tw`opacity-0 translate-y-1`)),
            enterTo: cx(css(tw`opacity-100 translate-y-0`)),
            leave: cx(css(tw`transition ease-in duration-150`)),
            leaveFrom: cx(css(tw`opacity-100 translate-y-0`)),
            leaveTo: cx(css(tw`opacity-0 translate-y-1`)),
          }}
        >
          <Popover.Panel static tw="relative z-10 w-full top-4 sm:px-0">
            <div tw="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <div tw="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                {resultCount > 0
                  ? results!.map((item) => (
                      <Link key={item.id} href={`user/${item.id}`} passHref={true}>
                        <a tw="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                          {/*<div tw="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12">*/}
                          <ProfilePhoto
                            size={'sm'}
                            photoUrl={item.identity?.profilePhoto}
                            displayName={item.username}
                          />
                          {/*</div>*/}
                          <div tw="ml-4">
                            <p tw="text-sm font-medium text-gray-900">{item.username}</p>
                            <p tw="text-sm text-gray-500">{item.identity?.name || ''}</p>
                          </div>
                        </a>
                      </Link>
                    ))
                  : 'No results.'}
              </div>
              <div tw="p-4 bg-gray-50">
                <Link href="/updates/your-community" passHref={true}>
                  <a tw="flow-root px-2 py-2 transition duration-150 ease-in-out rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                    <span tw="flex items-center">
                      <span tw="text-sm font-medium text-gray-900">Register your community</span>
                    </span>
                    <span tw="block text-sm text-gray-500">
                      Can&apos;t find your people? Click here to see how to get involved.
                    </span>
                  </a>
                </Link>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}

const GymIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="8" fill="#FFEDD5" />
    <path
      d="M24 11L35.2583 17.5V30.5L24 37L12.7417 30.5V17.5L24 11Z"
      stroke="#FB923C"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7417 19.8094V28.1906L24 32.3812L31.2584 28.1906V19.8094L24 15.6188L16.7417 19.8094Z"
      stroke="#FDBA74"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7417 22.1196V25.882L24 27.7632L27.2584 25.882V22.1196L24 20.2384L20.7417 22.1196Z"
      stroke="#FDBA74"
      strokeWidth="2"
    />
  </svg>
)

export const LoadingSpinner = () => (
  <svg
    tw="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle tw="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path
      tw="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
)
